import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import Home from 'client/pages/Home';

import { getProductCategories } from 'database/product-categories';
import { getHomePage } from 'database/home-page';

export const getStaticProps = async ({ locale, defaultLocale }: GetStaticPropsContext) => {
  const [homePage, categories] = await Promise.all([getHomePage(), getProductCategories()]);

  return {
    props: {
      ...(await serverSideTranslations(locale || defaultLocale!)),
      categories,
      metaTitle: homePage.metaTitle[locale || defaultLocale!] || '',
      metaDescription: homePage.metaDescription[locale || defaultLocale!] || '',
      text: homePage.text[locale || defaultLocale!] || '',
      gallerySlides: homePage.gallerySlides.map(gallerySlide => ({
        imageSrc: gallerySlide.image?.src,
        imagePositionX: gallerySlide.imagePositionX,
        imagePositionY: gallerySlide.imagePositionY,
        title: gallerySlide.title[locale || defaultLocale!],
        description: gallerySlide.description[locale || defaultLocale!] || '',
        actionText: gallerySlide.actionText[locale || defaultLocale!],
        actionLink: gallerySlide.actionLink,
      })),
    },
  };
};

const HomePage = (props: InferGetStaticPropsType<typeof getStaticProps>) => {
  return <Home {...props} />;
};

export default HomePage;
