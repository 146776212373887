import React from 'react';

import Layout from 'client/components/Layout';
import MadeInFrance from 'client/components/MadeInFrance';
import Markdown from 'client/components/Markdown';
import PageContainer from 'client/components/PageContainer';
import TrackPages from 'client/components/TrackPages';

import Divider from 'client/elements/Divider';
import IconButton from 'client/elements/IconButton';
import { KeyboardArrowDownRoundedIcon } from 'client/elements/Icons';
import Typography from 'client/elements/Typography';

import { useTranslation } from 'client/utils/i18n';
import { scrollTo } from 'client/utils/scrollTo';

import { Categories } from 'types/categories';

import { getCategoryUrl } from 'utils/category';

import Category from './Category';
import Gallery, { GalleryProps } from './Gallery';
import styles from './index.module.css';

interface HomeProps {
  categories: Categories;
  gallerySlides: GalleryProps['slides'];
  metaTitle: string;
  metaDescription: string;
  text: string;
}
const Home = ({ categories, gallerySlides, metaTitle, metaDescription, text }: HomeProps) => {
  const { t, i18n } = useTranslation();

  const containerRef = React.useRef<HTMLDivElement>(null);

  return (
    <Layout title={metaTitle} description={metaDescription}>
      <TrackPages />
      <section className={styles.galleryContainer}>
        <Gallery slides={gallerySlides} />
        <MadeInFrance className={styles.madeInFrance} color="white" />
        <IconButton
          className={styles.scrollDownButton}
          size="small"
          onClick={() => scrollTo(containerRef.current!)}
        >
          <KeyboardArrowDownRoundedIcon className={styles.scrollDownIcon} />
        </IconButton>
      </section>

      <div ref={containerRef}>
        <section>
          <Typography variant="h2" align="center" className={styles.title}>
            {t('main:shop_by_category')}
          </Typography>

          {categories.map((category, index) => {
            return (
              <div key={index}>
                <Category
                  imageUrl={category.image?.src}
                  imageTitle={
                    category.image?.title?.[i18n.language] || category.image?.alt[i18n.language]
                  }
                  link={getCategoryUrl(category)}
                  name={category.name[i18n.language]}
                  description={category.metaDescription[i18n.language]}
                  isReversed={index % 2 !== 0}
                />
                <Divider />
              </div>
            );
          })}
        </section>

        <PageContainer>
          <Markdown>{text}</Markdown>
        </PageContainer>
      </div>
    </Layout>
  );
};

export default Home;
