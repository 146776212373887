import MuiTable, { TableProps as MuiTableProps } from '@mui/material/Table';

import styles from './Table.module.css';

export type TableProps = MuiTableProps;
const Table = ({ children, className, ...props }: TableProps) => {
  return (
    <MuiTable {...props} className={styles.table}>
      {children}
    </MuiTable>
  );
};

export default Table;
