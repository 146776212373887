import classNames from 'clsx';

import Button from 'client/elements/Button';
import Grid from 'client/elements/Grid';
import Link from 'client/elements/Link';
import Typography from 'client/elements/Typography';

import { useTranslation } from 'client/utils/i18n';

import styles from './Category.module.css';

interface Category {
  imageUrl?: string;
  imageTitle?: string;
  link: string;
  name?: string;
  description?: string;
  isReversed: boolean;
}

const Category = ({ imageUrl, imageTitle, link, name, description, isReversed }: Category) => {
  const { t } = useTranslation();

  return (
    <Grid container={true} justifyContent="center" direction={isReversed ? 'row-reverse' : 'row'}>
      <Grid item={true} sm={12} md={6} className={styles.imageContainer}>
        <Link
          className={classNames(styles.imageLink, {
            [styles.reverse]: isReversed,
          })}
          href={link}
          title={imageTitle}
        >
          <span
            role="img"
            title={imageTitle}
            className={styles.image}
            style={{ backgroundImage: `url(${imageUrl})` }}
          />
        </Link>
      </Grid>
      <Grid
        item={true}
        sm={12}
        md={6}
        className={classNames(styles.info, {
          [styles.reverse]: isReversed,
        })}
      >
        <div>
          {name && (
            <Typography variant="h3" gutterBottom={true}>
              {name}
            </Typography>
          )}
          {description && <Typography gutterBottom={true}>{description}</Typography>}
          <Button
            component={Link}
            // @ts-ignore: Link component props
            underline="none"
            href={link}
            variant="contained"
            color="secondary"
            fullWidth={false}
          >
            {t('main:view_products')}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default Category;
