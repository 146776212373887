import React from 'react';

const ANALYTICS_ID = 'UA-3864449-1';

const TrackPageView = () => {
  React.useEffect(() => {
    window.gtag('config', ANALYTICS_ID);
  }, []);

  return null;
};

export default TrackPageView;
